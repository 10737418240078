import { LitElement, html } from 'lit';

import install from '@twind/with-web-components';
import config from '../../../twind.config';

const withTwind = install(config);

class SimpleCardComponent extends withTwind(LitElement) {

  static properties = {
    loading: { type: Boolean },
    roundedSize: { type: String },
    transparent: { type: Boolean }
  };

  get customizeStyle() {
    return html``;
  }

  constructor() {
    super();
    this.roundedSize = 'rounded-2xl';
    this.transparent = false;
  }

  render() {
    return html`
            <style>
            ${this.customizeStyle}
            </style>
            <div class="h-full ${this.roundedSize} ${this.transparent ? 'bg-transparent' : 'bg-white'} w-full" style="${this.transparent ? '' : 'box-shadow: 0px 1px 10px #00000021;'} break-inside: avoid;">
                <slot name="card-header"></slot>
                <slot name="card-content"></slot>
            </div>
            `;
  }

}

customElements.define('wb-simple-card', SimpleCardComponent);
